import React from 'react';
import './Banner.css';
import BannerVideo from '../../../video/interfaith.mp4';
import SliderCarousel from './SliderCarousel';
//


function Banner() {

  return (
    <div className='landingPageBanner'>
      <div className='parent'>
        <div className='child'>
          <video
            autoPlay
            muted
            controls={false}
            loop={true}
            // Add other attributes like src, width, height, etc. as needed
          >
            <source src={BannerVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className='child'>
          <SliderCarousel />
        </div>
      </div>

    </div>
  );
}

export default Banner;
