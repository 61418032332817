import React, {useState} from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import './App.css';
import Home from './components/Home/Home';
import LandingPage from './components/LandingPage/LandingPage';
import WhoWeAre from './components/Pages/WhoWeAre/WhoWeAre';
import Header from './components/Header/Header';
import Navbar from './components/Layouts/Navbar/Navbar';
import Footer from './components/Layouts/Footer/Footer';
import WhatWeDo from './components/Pages/WhatWeDo/WhatWeDo';
import WhereWeWork from './components/Pages/WhereWeWork/WhereWeWork';
import Research from './components/Pages/Research/Research';
import Community from './components/Pages/Community/Community';
import Education from './components/Pages/Education/Education';
import Division from './components/Pages/Division/Division';
import Stories from './components/Pages/Stories/Stories';
import Articles from './components/Pages/Articles/Articles';
import AllStories from './components/Pages/AllStories/AllStories';
import AllArticles from './components/Pages/AllArticles/AllArticles';
import AllResearches from './components/Pages/AllResearches/AllResearches';
import Login from './components/Pages/Login/Login';

import Create from './components/Pages/User/Create';
import Index from './components/Pages/User/Index';
import Edit from './components/Pages/User/Edit';
import Show from './components/Pages/User/Show';
import IndexStory from './components/Pages/DashStories/IndexStory';
import EditStory from './components/Pages/DashStories/EditStory';
import ShowStory from './components/Pages/DashStories/ShowStory';
import CreateStory from './components/Pages/DashStories/CreateStory';
import CreateResearch from './components/Pages/DashResearch/CreateResearch';
import IndexResearch from './components/Pages/DashResearch/IndexResearch';
import EditResearch from './components/Pages/DashResearch/EditResearch';
import ShowResearch from './components/Pages/DashResearch/ShowResearch';
import CreateDonation from './components/Pages/DashDonations/CreateDonation';
import IndexDonation from './components/Pages/DashDonations/IndexDonation';
import EditDonation from './components/Pages/DashDonations/EditDonation';
import ShowDonation from './components/Pages/DashDonations/ShowDonation';
import Division2 from './components/Pages/Division/Division2';
import Division3 from './components/Pages/Division/Division3';
import Gallery from './components/Pages/Gallery/Gallery';
import Donate from './components/Pages/Donate/Donate';
import CreateGallery from './components/Pages/DashGallery/CreateGallery';
import IndexGallery from './components/Pages/DashGallery/IndexGallery';
import EditGallery from './components/Pages/DashGallery/EditGallery';
import ShowGallery from './components/Pages/DashGallery/ShowGallery';
import Interfaith from './components/Pages/Interfaith/Interfaith';
import Protected from './Protected';
import CreatePodcast from './components/Pages/DashPodcast/CreatePodcast';
import IndexPodcast from './components/Pages/DashPodcast/IndexPodcast';
import EditPodcast from './components/Pages/DashPodcast/EditPodcast';
import ShowPodcast from './components/Pages/DashPodcast/ShowPodcast';
import CreateEvent from './components/Pages/DashEvent/CreateEvent';
import IndexEvent from './components/Pages/DashEvent/IndexEvent';
import EditEvent from './components/Pages/DashEvent/EditEvent';
import ShowEvent from './components/Pages/DashEvent/ShowEvent';
import AllEvents from './components/Pages/AllEvents/AllEvents';
import AllPodcasts from './components/Pages/AllPodcasts/AllPodcasts';
import Events from './components/Pages/Events/Events';
import Podcasts from './components/Pages/Podcast/Podcast';
import EditEmail from './components/Pages/DashEmail/EditDonation';
import ShowEmail from './components/Pages/DashEmail/ShowEmail';
import IndexEmail from './components/Pages/DashEmail/IndexEmail';
import CreateEmail from './components/Pages/DashEmail/CreateEmail';
import ShowTeam from './components/Pages/DashTeam/ShowTeam';
import EditTeam from './components/Pages/DashTeam/EditTeam';
import IndexTeam from './components/Pages/DashTeam/IndexTeam';
import CreateTeam from './components/Pages/DashTeam/CreateTeam';
import TeamType from './components/Pages/TeamType/TeamType';
import ElectionCalendar from './components/Pages/Events/ElectionCalendar';
import Action from './components/Pages/Action/Action';
import Resource from './components/Pages/Resource/Resource';
import Election from './components/Pages/Events/Election';
import CreateElection from './components/Pages/DashElection/CreateElection';
import IndexElection from './components/Pages/DashElection/IndexElection';
import EditElection from './components/Pages/DashElection/EditElection';
import ShowElection from './components/Pages/DashElection/ShowElection';
import ShowActionNetwork from './components/Pages/DashActionNetwork/ShowActionNetwork';
import EditActionNetwork from './components/Pages/DashActionNetwork/EditActionNetwork';
import IndexActionNetwork from './components/Pages/DashActionNetwork/IndexActionNetwork';
import CreateActionNetwork from './components/Pages/DashActionNetwork/CreateActionNetwork';
import ShowArticle from './components/Pages/DashArticles/ShowArticle';
import EditArticle from './components/Pages/DashArticles/EditArticle';
import IndexArticle from './components/Pages/DashArticles/IndexArticle';
import CreateArticle from './components/Pages/DashArticles/CreateArticle';
import Vote from './Vote';
import KenyaMain from './KenyaMain';
import Usda from './Usda';

function Dash() {
  const [togActive, setTogActive] = useState(false);
  const handleToggle = () => {
    setTogActive(!togActive);
  };


  return (
    <div>
      <Header statey={togActive} handleToggle={handleToggle} />
      <Routes>
        <Route path="/" element={<Home handleToggle={handleToggle} togActive={togActive} />} />

        <Route path="/create-user" element={<Create handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/users" element={<Index handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/edit-user/:id" element={<Edit handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/show-user/:id" element={<Show handleToggle={handleToggle} togActive={togActive} />} />

        <Route path="/create-story" element={<CreateStory handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/stories" element={<IndexStory handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/story/:id" element={<EditStory handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/show-story/:id" element={<ShowStory handleToggle={handleToggle} togActive={togActive} />} />

        <Route path="/create-article" element={<CreateArticle handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/articles" element={<IndexArticle handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/edit-article/:id" element={<EditArticle handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/show-article/:id" element={<ShowArticle handleToggle={handleToggle} togActive={togActive} />} />

        <Route path="/create-research" element={<CreateResearch handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/researches" element={<IndexResearch handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/edit-research/:id" element={<EditResearch handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/show-research/:id" element={<ShowResearch handleToggle={handleToggle} togActive={togActive} />} />

        <Route path="/create-donation" element={<CreateDonation handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/donations" element={<IndexDonation handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/edit-donation/:id" element={<EditDonation handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/show-donation/:id" element={<ShowDonation handleToggle={handleToggle} togActive={togActive} />} />

        <Route path="/create-gallery" element={<CreateGallery handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/galleries" element={<IndexGallery handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/edit-gallery/:id" element={<EditGallery handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/show-gallery/:id" element={<ShowGallery handleToggle={handleToggle} togActive={togActive} />} />

        <Route path="/create-podcast" element={<CreatePodcast handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/podcasts" element={<IndexPodcast handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/edit-podcast/:id" element={<EditPodcast handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/show-podcast/:id" element={<ShowPodcast handleToggle={handleToggle} togActive={togActive} />} />

        <Route path="/create-email" element={<CreateEmail handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/emails" element={<IndexEmail handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/edit-email/:id" element={<EditEmail handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/show-email/:id" element={<ShowEmail handleToggle={handleToggle} togActive={togActive} />} />

        <Route path="/create-team" element={<CreateTeam handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/teams" element={<IndexTeam handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/edit-team/:id" element={<EditTeam handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/show-team/:id" element={<ShowTeam handleToggle={handleToggle} togActive={togActive} />} />

        <Route path="/create-event" element={<CreateEvent handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/events" element={<IndexEvent handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/edit-event/:id" element={<EditEvent handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/show-event/:id" element={<ShowEvent handleToggle={handleToggle} togActive={togActive} />} />

        <Route path="/create-election" element={<CreateElection handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/elections" element={<IndexElection handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/edit-election/:id" element={<EditElection handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/show-election/:id" element={<ShowElection handleToggle={handleToggle} togActive={togActive} />} />

        <Route path="/create-action-network" element={<CreateActionNetwork handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/action-networks" element={<IndexActionNetwork handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/edit-action-network/:id" element={<EditActionNetwork handleToggle={handleToggle} togActive={togActive} />} />
        <Route path="/show-action-network/:id" element={<ShowActionNetwork handleToggle={handleToggle} togActive={togActive} />} />
      </Routes>
    </div>
  );
}

function Landing() {
  
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/who-we-are" element={<WhoWeAre />} />
        <Route path="/team-type/:id" element={<TeamType />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/vote" element={<Vote />} />
        <Route path="/kenya" element={<KenyaMain />} />
        <Route path="/usda" element={<Usda />} />
        <Route path="/democratisation" element={<Division />} />
        <Route path="/culture-and-heritage" element={<Division2 />} />
        <Route path="/health-and-wellness" element={<Division3 />} />
        <Route path="/story/:id" element={<Stories />} />
        <Route path="/article/:id" element={<Articles />} />
        <Route path="/event/:id" element={<Events />} />
        <Route path="/election/:id" element={<Election />} />
        <Route path="/all-articles" element={<AllArticles />} />
        <Route path="/stories" element={<AllStories />} />
        <Route path="/divisions" element={<AllResearches />} />
        <Route path="/what-we-do" element={<WhatWeDo />} />
        <Route path="/how-we-work" element={<WhereWeWork />} />
        <Route path="/action-network" element={<Action />} />
        <Route path="/resources" element={<Resource />} />
        <Route path="/login" element={<Login />} />
        <Route path="/events" element={<AllEvents />} />
        <Route path="/election-calendar" element={<ElectionCalendar />} />
        <Route path="/podcasts" element={<AllPodcasts />} />
        <Route path="/podcast/:id" element={<Podcasts />} />
        <Route path="/aniee-lee's-porch" element={<Research />} />
        <Route path="/rosa's-food-pantry" element={<Community />} />
        <Route path="/the-plow-and-pew-democracy-center" element={<Education />} />
        <Route path="/ameenah's-interfaith-center" element={<Interfaith />} />
      </Routes>
      <Footer />
    </div>
  );
}

function App() {
 
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/*" element={<Landing />} />

          <Route exact path="/dashboard/*" element={ 
                <Protected Cmp={Dash} />
                }></Route>
          </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
