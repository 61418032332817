import React, { useEffect, useState } from 'react';
import './Usda.css'; 

function Usda() {
  const [timeRemaining, setTimeRemaining] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const targetDate = new Date('2024-09-28T00:00:00');

    // Countdown function
    const countdown = setInterval(() => {
      const now = new Date();
      const distance = targetDate - now;

      if (distance <= 0) {
        clearInterval(countdown);
        setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeRemaining({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  return (
    <div className="usda-container">
      {/* Header Section */}
      <header className="usda-header">
        <div className="usda-header-content">
          <h1>Welcome to the USDA One-Stop Service Center</h1>
          <p className="usda-header-intro">
            Empowering future agriculture professionals with education, resources, and hands-on experience.
          </p>
        </div>

        <div className="usda-video-highlight">
          <video width="100%" height="400" controls autoPlay loop muted preload="auto">
            <source src="/Final%20Setup_Vid.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="usda-header-description">
          <p>
            At the USDA One-Stop Service Center, we extend our dedication to cultivating the future of agriculture by empowering individuals to explore a range of dynamic career paths. With steadfast respect for tradition and innovation, we provide invaluable insights into agriculture and forest services, fostering the next generation of professionals committed to shaping a sustainable future.
          </p>
        </div>
      </header>

      {/* Services Section */}
      <section className="usda-services">
        <h2>Our Services and Resources</h2>
        <ul className="usda-services-list">
          <li>
            <strong>Comprehensive Career Guidance:</strong> Find tailored pathways in agriculture, from farm management to conservation and beyond.
          </li>
          <li>
            <strong>Internship and Training Opportunities:</strong> Develop practical skills through internships with USDA programs and partner organizations.
          </li>
          <li>
            <strong>Workshops and Educational Events:</strong> Engage with professionals and attend virtual workshops to learn about diverse agricultural careers.
          </li>
          <li>
            <strong>Resource Hub:</strong> Access tools, research, and information that support agricultural education and career development.
          </li>
        </ul>
      </section>

      {/* Target Audience Section */}
      <section className="usda-target-audience">
        <h2>Who This is For</h2>
        <p>
          Our program is designed for individuals passionate about the agriculture field and eager to contribute to the vital work of the USDA. Whether you're interested in farming, environmental conservation, or forest management, we provide the guidance and resources you need to navigate your career path.
        </p>
        <ul className="usda-audience-list">
          <li><strong>Aspiring Agricultural Professionals:</strong> Build a rewarding career in farming, environmental conservation, or forest management.</li>
          <li><strong>Students in Middle and High School:</strong> Explore agricultural careers through our career exploration programs.</li>
          <li><strong>College Students and Recent Graduates:</strong> Access internships and training opportunities to kickstart your career.</li>
          <li><strong>Anyone Interested in the USDA:</strong> Connect with USDA professionals, job openings, and internships.</li>
        </ul>
        <p>
          We are also excited to introduce a new work-based learning program tailored to middle and high school students in January 2025. This initiative allows young people to explore careers in agriculture, forestry, conservation, and food systems, contributing to the future of agriculture.
        </p>
      </section>

      {/* Upcoming Events Section */}
      <section className="usda-upcoming-events">
        <h2>Upcoming Events</h2>
        <p>
          Our upcoming live virtual workshops offer young people the chance to hear directly from professionals in the agriculture industry. These workshops provide firsthand knowledge about various careers, with an interactive space where students can ask questions, engage with experts, and begin their journey into agriculture.
        </p>
        <div className="usda-countdown-banner">
          <p className="countdown-animation">
            Countdown to the next live workshop: <strong>{timeRemaining?.days}d {timeRemaining?.hours}h {timeRemaining?.minutes}m {timeRemaining?.seconds}s remaining</strong>
          </p>
        </div>
      </section>

      {/* Resources Section */}
      <section className="usda-resources">
        <h2>Resources and Opportunities</h2>
        <div className="usda-resource-list">
          <h3>Job Openings</h3>
          <p>Explore the latest job opportunities with the USDA and other agriculture-related organizations. Positions include:</p>
          <ul>
            <li>Farm and Forest Management</li>
            <li>Agricultural Policy and Research</li>
            <li>Environmental Conservation</li>
            <li>Food Science and Safety</li>
          </ul>

          <h3>Internships</h3>
          <p>Gain hands-on experience with USDA internships, offering students and young professionals a chance to build their careers. Internship programs include:</p>
          <ul>
            <li>Forestry and Land Management Internships</li>
            <li>Sustainable Agriculture Research Programs</li>
            <li>Environmental Policy and Advocacy Internships</li>
          </ul>

          <h3>Youth Events</h3>
          <p>Stay connected with events designed for young people interested in agriculture. Upcoming events include:</p>
          <ul>
            <li>Youth Ag Days with local farms</li>
            <li>STEM Workshops for Agricultural Innovation</li>
            <li>USDA Youth Leadership Summits</li>
          </ul>
        </div>
      </section>

      {/* Media Spotlight Section */}
      <section className="usda-media-spotlight">
        <h2>Media Spotlight</h2>
        <p>Check out our media gallery featuring videos and photos of past workshops, internship success stories, and events.</p>
        <div className="usda-media-gallery">
          {/* Placeholder for images and videos */}
          <img src="/Desk Setup A.jpg" alt="Workshop" />
          <img src="/Desk Setup B.jpg" alt="Workshop" />
          <img src="/Desk Setup C.jpg" alt="Workshop" />
          <img src="/Monitor_CPU Setup A.jpg" alt="Workshop" />
          <img src="/Monitor_CPU Setup B.jpg" alt="Workshop" />
          <img src="/Monitor_CPU Setup C.jpg" alt="Workshop" />
          <img src="/Monitor_CPU Setup D.jpg" alt="Workshop" />
        </div>
      </section>
    </div>
  );
}

export default Usda;
